export const FORMAT_CURRENCY = (amount?: number) => {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    minimumFractionDigits: 0,
  });
  return formatter.format(amount || 0).replaceAll(".", ",");
};

export const CALCULATE_PRICE_AFTER_DISCOUNT = (
  priceAfterDiscount?: number,
  discount?: number
) => {
  const _priceAfterDiscount = Number(priceAfterDiscount) || 0;
  const _discount = Number(discount) || 0;
  return Math.floor(_priceAfterDiscount / (1 - _discount / 100));
};

export const CHECK_PERMISSION = (
  roles?: string | Array<string>,
  listRoles?: Array<string>
) => {
  if (!roles || !listRoles) return false;
  if (typeof roles === "string") {
    return listRoles.includes(roles);
  }
  return roles.every((x) => listRoles.includes(x));
};

export const RANDOM_6_CHARACTER = () => {
  return Math.floor(Math.random() * 100000)
    .toString()
    .padStart(6, "0");
};
export const RANDOM_PASSWORD = () => {
  let chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@^#$%^&*()";
  let string_length = 20;
  let randomstring = "";
  for (let i = 0; i < string_length; i++) {
    let rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }
  return randomstring;
};

export const FORMAT_COMMODITY = (type: string) => {
  switch (type) {
    case "VEHICLE":
      return "Xe";
    case "HOUSE":
      return "Nhà";
    default:
      return "";
  }
};
