import { ICustomer } from "services/adminOrder/types";
import http from "../http";

import { IAdmin, IParamsAdminCreate } from "./types";

export const apiAdminGetList = (params?: IParamsPagination) =>
  http.get<BaseAPIResponse<GenericData<ICustomer>>>("/users", {
    params,
  });

export const apiAdminGetDetail = (id: string) =>
  http.get<BaseAPIResponse<IAdmin>>(`/users/${id}`);

export const apiAdminRoleGetList = (params?: IParamsPagination) =>
  http.get<BaseAPIResponse<GenericData<any>>>("/roles", { params });

export const apiAdminCreate = (body?: IParamsAdminCreate) =>
  http.post<BaseAPIResponse<IAdmin>>("/api/admin/create", body);

export const apiAdminUpdate = (id: string, body: IParamsAdminCreate) =>
  http.patch<BaseAPIResponse<IAdmin>>(`/admin/update/${id}`, body);

export const apiAdminDelete = (id: string) =>
  http.delete<BaseAPIResponse<unknown>>(`/api/admin/delete/${id}`);

export const apiAdminLogin = (body?: { username: string; password: string }) =>
  http.post<BaseAPIResponse<{ accessToken: string }>>("api/admin/login", body);

export const apiAdminLogout = () =>
  http.post<BaseAPIResponse<unknown>>("/auth/logout");

export const apiAdminGetInfo = () =>
  http.get<BaseAPIResponse<IAdmin>>("/auth/me");

export const apiGetMasterData = () =>
  http.get<BaseAPIResponse<IAdmin>>("/master-data");

export const apiGetDistrict = () =>
  http.get<BaseAPIResponse<IAdmin>>("/cadastral/district/31?limit=10");
