import { Error, Layout } from "components";
import { PATHS, ROUTES } from "constants/routes";
import { withPrivate } from "hocs/withPrivate";
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

const Login = React.lazy(() => import("pages/login/detail"));

const PAGE_ROUTES = [...ROUTES];
PAGE_ROUTES.forEach((x: any) => {
  x.element = React.lazy(() => import(`pages/${x.page}`));
});

const LayoutHoc = withPrivate(Outlet);

function App() {
  return (
    <Router>
      <Suspense fallback={null}>
        <Routes>
          <Route path={PATHS.login} element={<Login />} />
          <Route path="/" element={<LayoutHoc />}>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Navigate to={PATHS.listNews} />} />
              {PAGE_ROUTES.map((item, index) => {
                const PAGE = (item as any).element || null;
                return (
                  <Route
                    key={index}
                    path={item.href}
                    element={
                      <Suspense fallback={null}>
                        <PAGE />
                      </Suspense>
                    }
                  />
                );
              })}
            </Route>
            <Route path={"*"} element={<Error error="404" />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
